import React from "react"
import { graphql } from "gatsby"
import Layout from '../components/Layout'
import templateStyles from '../css/single-blog.module.css'
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import SEO from "../components/SEO"

const Post = ({ data }) => {
 const { title, published, text: {json}} = data.post
  const options = {
   renderNode: {
     "embedded-asset-block":(node) => {
       return <div className={templateStyles.imgContainer}>
         <img width="400" src={node.data.target.fields.file['en-US'].url} alt="Blog" className={templateStyles.img}/>
       </div>
     }
   }
  }
  return (
    <Layout>
      <SEO title={title} />
      <section className={templateStyles.blog}>
        <div className={templateStyles.center}>
          <h1>{ title }</h1>
          <h4>{ published }</h4>
          <article className={templateStyles.post}>
            {documentToReactComponents(json, options)}
          </article>
          <AniLink fade to='/blogs' className="btn-primary">
            All Posts
          </AniLink>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
    query($slug: String!) {
        post: contentfulPost(slug: {eq: $slug}) {
            title
            published(formatString: "Do MMMM Y")
            text {
                json
            }
        }
    } 
`

export default Post
